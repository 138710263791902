<template>
  <v-dialog
      v-model="value"
      persistent
      min-width="400"
      max-width="600"
    >
    <v-card class="pa-5">
      <h2>Gib deinen Token an:</h2><br>
      <v-expand-transition>
        <v-text-field
          v-if="logged"
          v-model="userName"
          label="Angemeldet als:"
          readonly
        />
      </v-expand-transition>
      <v-text-field
        v-if="!logged"
        type="password"
        v-model="token"
        label="Token eingeben..."
        outlined
        :error="error"
        :success="logged"
        />
        <v-card-actions class="justify-space-around">
          <v-btn
            v-if="logged"
            color="error"
            text
            @click="checkToken('')"
          >
            ausloggen
          </v-btn>
          <v-btn
            v-else
            color="success"
            text
            :disabled="unchangedToken"
            @click="checkToken(token)"
          >
            einloggen
          </v-btn>
          <v-btn
            color="error"
            text
            @click="$emit('input', false)"
            >
            schließen
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
// import Card from '@/components/Card'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'LogIn',
  components: {
    // Card
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      token: null,
      error: false
    }
  },
  computed: {
    ...mapState(
      ['logged', 'loading', 'user']
    ),
    ...mapState(
      'user', [
        'userName',
        'email'
      ]
    ),
    ...mapGetters({
      storedToken: 'getToken'
    }),
    unchangedToken() {
      return this.token === this.storedToken
    }
  },
  methods: {
    ...mapActions('notification', { notify: 'set' }),
    async checkToken (token) {
      if (token === null) {
        this.error = true
      } else {
        await this.$store.dispatch('getUserData', token)
        this.token = this.storedToken
        if (this.logged === true) {
          this.error = false
          this.notify({ massage: this.user.userName })
          // this.$cookies.set('token', token)
          this.$emit('input', false)
        } else {
          this.error = !(token === '')
        }
      }
    }
  },
  created () {
    if (this.storedToken != null && this.logged === true) {
      this.token = this.storedToken
    }
  }
}
</script>
