<template>
    <div>
      <v-expand-transition>
      <div :class="menu ? 'banner-menu' : 'banner' " v-if="this.$store.state.smap.id != null" >
        <br>
        <center>
          <v-alert
            border="left"
            color="#555"
            elevation="2"
            dark
          >
            <v-row class="justify-space-around" align="center">
              <v-col >
                <b>{{this.$store.state.smap.name}}</b>
              </v-col>
              <v-col>
                {{this.$store.state.smap.id}}
              </v-col>
            </v-row>
          </v-alert>
        </center>
      </div>
      </v-expand-transition>
      <v-expand-transition>
        <div class="space" v-if="this.$store.state.smap.id != null"></div>
      </v-expand-transition>
    </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'SmapInfo',
  props: {
    menu: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.banner {
  position: fixed;
  z-index: 10;
  left: 50px;
  right: 50px;
}
.banner-menu {
  position: fixed;
  z-index: 10;
  left: 300px;
  right: 50px;
}
.space {
  height: 80px;
}
</style>