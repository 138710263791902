<template>
  <div class="mx-10">
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Suche"
      single-line
      hide-details
      solo
      clearable
    ></v-text-field>
    <v-data-table
      v-model="selected"
      item-key="smapId"
      :headers="headers"
      :items="smaps"
      :search="search"
      :items-per-page="15"
      class="elevation-1"
      show-select
      single-select
    ></v-data-table>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex'

export default {
  name: 'SmapList',
  data: () => ({
    search: '',
    selected: [],
    headers: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Smap ID',
        value: 'smapId'
      },
      {
        text: 'letzte Änderung',
        value: 'lastChanged'
      }
    ]
  }),
  computed: {
    ...mapState(['smaps']),
    ...mapState('smap', ['id'])
  },
  methods: {
    saveSmap: async function () {
      await this.$store.dispatch('smap/loadSmap', this.selected[0])
    }
  },
  watch: {
    selected(){
      this.saveSmap()
    }
  },
  mounted () {
    this.selected = [this.smaps.find(smap => smap.smapId === this.id)]
  }
}
</script>

<style>

</style>