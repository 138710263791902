<template>
  <div class="start">
    <SmapList />
  </div>
</template>

<script>
// @ is an alias to /src
import SmapList from '@/components/SmapList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Start',
  components: {
    SmapList
  },
  computed: mapGetters({
    token: 'getToken'
  }),
  async mounted () {
    await this.$store.dispatch('getSmaps', this.token)
  }
}
</script>
