import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '../views/Start.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Start',
  component: Start,
  icon: 'mdi-home',
  meta: {
    description: {
      text: 'Hallo, ich bin Jans SmapTool. \nIch kann dir helfen Smaps automatisiert zu bearbeiten. \nBedenke, dass ich noch ein Prototyp bin...',
      img: null
    }
  }
},
{
  path: '/Replicator',
  name: 'Replicator',
  icon: 'mdi-format-list-bulleted-type',
  meta: {
    description: {
      text: 'Jede Zeile in der Textbox unten steht für eine Kopie des ausgewählten Bricks.',
      img: '@/assets/infographics/Replicator.png'
    }
  },
  component: () => import(/* webpackChunkName: "Replicator" */ '@/views/Replicator.vue')
},
{
  path: '/Renamer',
  name: 'Renamer',
  icon: 'mdi-cursor-text',
  meta: {
    description: {
      text: 'Die IDs der Bricks werden nach ihren Labels benannt.',
      img: '@/assets/infographics/Renamer.png'
    }
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "Renamer" */ '@/views/Renamer.vue')
},
{
  path: '/DataSource',
  name: 'DataSource',
  icon: 'mdi-cursor-text',
  meta: {
    description: {
      text: 'Die IDs der Bricks werden nach ihren Labels benannt.',
      img: '@/assets/infographics/Renamer.png'
    }
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "CSV2DataSource" */ '@/views/CSV2DataSource.vue')
},
{
  path: '/Text2Number',
  name: 'Text2Number',
  icon: 'mdi-cursor-text',
  meta: {
    description: {
      text: '',
      img: null
    }
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "Text2Number" */ '@/views/Text2Number.vue')
}
/*
{
  path: '/ocr',
  name: 'OCR',
  icon: 'mdi-camera-metering-center',
  meta: {
    description: {
      text: 'Texterkennung eines Fotos.'
    }
  },
  component: () => import(/* webpackChunkName: "OCR" *//* '@/views/OCR.vue')
}
*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
