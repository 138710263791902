import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const key = 'S@XpTsY#NRobPxq5FLdqryafP'
const CryptoJS = require('crypto-js')

export default new Vuex.Store({
  state: {
    api: 'https://smaptools-api.pietschcloud.de',
    backend: 'https://platform.smapone.com/Backend/intern',
    loading: false,
    token: '',
    logged: null,
    smaps: [],
    cache: {
    }
  },
  getters: {
    getToken (state) {
      return CryptoJS.AES.decrypt(state.token, key).toString(CryptoJS.enc.Utf8)
    },
    getMinSmaps (state) {
      const smaps = state.smaps.map(e => (
        {
          id: e.smapId,
          name: e.name
        }
      ))
      return smaps
    }
  },
  mutations: {
    changeLoadingStatus (state, status) {
      state.loading = status
    },
    setToken (state, token) {
      state.token = CryptoJS.AES.encrypt(token, key).toString()
    },
    setLoggingState (state, status) {
      state.logged = status
    },
    storeSmaps (state, smaps) {
      state.smaps = smaps
    },
    assignCache (state, payload) {
      const cache = state.cache
      state.cache = {}
      state.cache = Object.assign(cache, payload)
    },
    clearCache (state, payload) {
      state.cache = {}
    },
    overwriteCache (state, payload) {
      state.cache = payload
    }
  },
  actions: {
    smapOne (context, payload) {
      return this.state.backend + '/' + payload.path + '?accesstoken=' + payload.token
    },
    apiGET (context, payload) {
      return this.state.api + '/' + payload.path + '/' + payload.id + '?token=' + payload.token
    },
    getSmaps: async function (context, token) {
      context.commit('changeLoadingStatus', true)
      const url = await context.dispatch('smapOne', { path: 'Smaps', token: token })
      const response = await (await fetch(url)).json()
      context.commit('storeSmaps', response)
    },
    getUserData: async function (context, token) {
      context.commit('changeLoadingStatus', true)
      context.commit('setLoggingState', false)
      context.commit('setToken', null)
      const url = await context.dispatch('smapOne', { path: 'Account', token: token })
      const response = await (await fetch(url)).json()
      if (response.accountIsActivated === true) {
        context.commit('setToken', token)
        context.commit('setLoggingState', true)
        context.commit('user/setUserData', response)
        context.dispatch('getSmaps', token)
      }
      context.commit('changeLoadingStatus', false)
    },
    assignCache (context, payload) {
      context.commit('changeLoadingStatus', true)
      context.commit('assignCache', payload)
      context.commit('changeLoadingStatus', false)
    }
  },
  modules: {
    user: {
      namespaced: true,
      state: {
        userName: null,
        email: null
      },
      mutations: {
        setUserData (state, data) {
          state.userName = data.userName
          state.email = data.email
        }
      }
    },
    smap: {
      namespaced: true,
      state: {
        id: null,
        name: null,
        bricks: [],
        minBricks: []
      },
      mutations: {
        setSmap (state, smap) {
          state.id = smap.smapId
          state.name = smap.name
        },
        storeMinBricks (state, bricks) {
          state.minBricks = bricks
        }
      },
      actions: {
        getMinBricks: async function (context) {
          const payload = {
            path: 'brickIds',
            id: this.state.smap.id,
            token: this.getters.getToken
          }
          const url = await context.dispatch('apiGET', payload, { root: true })
          const response = await (await fetch(url)).json()
          context.commit('storeMinBricks', response)
        },
        loadSmap: async function (context, smap) {
          context.commit('changeLoadingStatus', true, { root: true })
          context.dispatch('clearSmap')
          context.commit('setSmap', smap)
          context.dispatch('getMinBricks')
          context.commit('changeLoadingStatus', false, { root: true })
        },
        clearSmap: function (context) {
          context.commit('clearCache', true, { root: true })
          const smap = {
            id: null,
            name: null,
            bricks: [],
            minBricks: []
          }
          context.commit('setSmap', smap)
        }
      }
    },
    notification: {
      namespaced: true,
      state: {
        init: {
          massage: null,
          icon: null,
          color: null,
          timeout: 3000
        },
        trigger: false,
        payload: {}
      },
      mutations: {
        reset (state) {
          state.trigger = false
          state.payload = state.init
        },
        assignPayload (state, payload) {
          state.payload = Object.assign(state.payload, payload)
        },
        trigger (state) {
          state.trigger = true
        }
      },
      actions: {
        set (context, payload) {
          context.commit('reset')
          context.commit('assignPayload', payload)
          context.commit('trigger')
          setTimeout(() => { context.commit('reset') }, context.state.payload.timeout)
        }
      }
    }
  },
  plugins: [new VuexPersistence().plugin]
})
